.App {
  text-align: left;
  display: flex;
  background-color: white;
  color: #150065;
  font-family: 'Söhne', sans-serif;
  position:absolute;
  top:0;bottom:0;left:0;right:0;
}

.sidemenu {
  display: flex;
  flex-direction: column;
  width: 220px;
  padding: 5px;
  color: #ffffff;
  background-color: #150065;
  position: relative;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  font-size: 14px;
  overflow: hidden; /* Ensure overflow is hidden */
  min-height: 70%;
  min-width: 110px;

}

.conversation-section {
  flex-grow: 1; /* Allow it to take up remaining space */
  overflow-y: auto; /* Enable scrolling if content overflows */
  overflow-x: hidden;
}

.conversation-section .conversations span {
  margin-left:5px;
  padding-left:10px;
  padding-right:80px;
  border-bottom: 2px solid #00ffc8aa; /* Adjust color and width as needed */
  padding-bottom: 5px; /* Adjust as needed */
  flex-grow: 1; /* Allow it to take up remaining space */
  overflow-y: auto; /* Enable scrolling if content overflows */
}

.thread-entry {
  display: flex;
  align-items: center;
  justify-content: space-between; /* Ensures space between text and icon */
  margin-left:5px;
  margin-right: 10px;
  padding-left: 10px;
  cursor: pointer;
  font-size: 12px;
  display: flex;
  border-radius: 10px;
  margin-bottom: 5px;
  background-color: #9d9b9b77; /* Example background color for contrast */
  transition: background-color 0.25s ease; /* Smooth transition for background color */
}

.thread-text {
  flex: 1; /* Takes up the remaining space */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; /* Apply ellipsis to the thread text only */
}

.thread-entry:hover{
  background-color: #00ffc8aa;
}

.side-menu-button {
  display: flex; /* Use flexbox for alignment */
  justify-content: space-between; /* Space content between left and right sides */
  align-items: center; /* Center items vertically */
  padding-left: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 15px;
  text-align: left;
  margin-top: 5px;
  border-radius: 10px;
  transition: ease 0.25s all;
  background-color: #9d9b9b77;
  cursor:pointer;
}

.side-menu-button:hover {
  background-color: #00ffc8aa;
}

.bottom-container {
  position: relative;
  bottom: 0; /* Position at the bottom of the sidemenu */
  padding: 5px; /* Add padding at the top and bottom */
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex; /* Use flexbox for layout */
  align-items: center; /* Align items vertically */
  width: 90%; /* Ensure the container takes full width of its parent */
  gap: 20px; /* Space between buttons */
}

.logout-button, .admin-button {
  padding: 10px;
  background-color: #9d9b9b77; /* Semi-transparent gray */
  color: white;
  border: none;
  border-radius: 20px;
  cursor: pointer;
}


.logout-button:hover {
  background-color: #00ffc8aa; /* Darker blue on hover */
}

.admin-button:hover {
  background-color: #00ffc8aa; /* Darker blue on hover */
}
/* Ensure the SVG inherits color for consistent styling */
.icon-md {
  fill: currentColor; /* Inherit the color from parent */
}

.side-menu-button span {
  display: flex;
  align-items: center; /* Aligns the SVG icon vertically with the text */
  gap: 10px; /* Optional: adds space between the SVG icon and text */
}
.side-menu-button svg {
  width: 3vw; /* 3% of the viewport width */
  height: 3vw; /* Keeps the aspect ratio the same as width */
}

/* Default size */
.side-menu-button svg {
  width: 24px;
  height: 24px;
}

.chat-box {
  display: flex;
  flex-direction: column;
  height: 100%; /* Set the chatbox height */
  width: 100%;
  position: relative; /* Add relative positioning here */
}

.chat-log-container {
  overflow-y: auto;
  overflow-x: hidden; /* Prevent horizontal overflow */
  flex-grow: 1;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  height: 100%;
}


.chat-log-container.center {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  height: 100%; /* Ensure the container takes the full height */
}

.chat-input-holder {
  display: flex;
  padding: 10px;
  position: absolute; 
  bottom: 0;
  left: 0;
  right: 0;
  align-items: center; /* Ensure the children are centered vertically */
}

.chat-input-form {
  display: flex;
  align-items: center; /* Ensure the children are centered vertically */
  width: 100%; /* Ensure the form takes the full width */
}

.chat-input-textarea {
  background-color: white;
  width: calc(100% - 80px); /* Subtract the total horizontal padding from width */
  padding: 12px;
  color: grey;
  align-items: center;
  font-size: 1.25em;
  border-radius: 20px;
  border: 1px solid lightgray; /* Adjusted the border property */
  outline: none; /* Use 'none' to remove the default focus outline */
  box-shadow: 0 0 0 0px rgba(0,0,0,0.25);
  max-height: 140px; /* Maximum height to show 7 lines */
  overflow-y: auto; /* Enable vertical scrolling */
  font-size: 16px;
}

.chat-input-textarea.disabled {
  background-color: #f3f3f3; /* Light grey background */
  color: #ccc; /* Greyed out text color */
  cursor: not-allowed; /* Shows a 'not allowed' cursor when hovering */
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@media (max-width: 600px) {
  .sidemenu {
    width: 100px; /* Shrink the side menu width */
    font-size: 12px; /* Reduce font size */
  }

  .side-menu-button {
    padding: 5px; /* Reduce padding */
    font-size: 12px; /* Reduce font size */
  }

  .side-menu-button span {
    display: inline; /* Ensure text is displayed on mobile */
    margin-left: 5px; /* Add some space between the icon and text */
  }
  .thread-entry {
    font-size: 10px; /* Reduce font size */
  }

  .bottom-container {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    padding: 5px;
  }

  .logout-button,
  .admin-button {
    font-size: 12px; /* Reduce font size */
    padding: 5px; /* Reduce padding */
  }

  .chat-box {
    width: 100%;
    align-items: center;
    padding: 10px; /* Add padding for better spacing */
  }

  .user-activity-table {
    width: 100%; /* Full width table on mobile */
  }

  .user-activity-table th,
  .user-activity-table td {
    padding: 5px; /* Reduce padding for table cells */
  }

  .admin-sidebar {
    width: 100px; /* Adjust width for admin sidebar */
  }

  .admin-sidebar li {
    font-size: 12px; /* Reduce font size for list items */
  }

  .admin-content {
    padding: 10px; /* Add padding for better spacing */
    width: 100%;
  }

  .chat-input-holder {
    left:0;
    bottom: 0;
    padding: 20px;
    bottom: 10px; /* Adjust bottom spacing */
  }
}

/* Class to add margin when side menu is visible */
.with-side-menu {
  padding-left: 3%; /* Adjust based on your side menu width */
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.chat-log {
  text-align: left;
}

.chat-header {
  position: sticky;
  top: 0; /* Adjust if you have a top navbar and you want the header to stick below it */
  background: #ffffff; /* Background color for the header */
  padding-left: 60px;
  color: #150065; /* Text color for the header */
  z-index: 10; /* Ensure the header stays on top of other content when scrolling */
}

.chat-header select{
  border-radius: 10px;
  border: none;
  padding:5px;
  background-color: #d1cfcf77;
}

.chat-message {
  overflow-wrap: break-word; /* Break long words to prevent overflow */
}

.chat-message.assistant {
  background-color: #ffffff;
}
.chat-message-center {
  width: 100%; /* Ensure the message takes the full width */
  margin-left: auto;
  margin-right: auto;
  display: flex;
  padding: 20px;
  padding-left: 24px;
  padding-right: 24px;
  box-sizing: border-box; /* Ensure padding is included in the element's total width and height */
}


.avatar { 
  flex-shrink: 0; /* Prevent the avatar from shrinking */
  background: #87d6d5;
  border-radius: 50%;
  width: 42 px;
  height: 42px;
}

.avatar.assistant { 
  background: rgb(238, 237, 237);
}

.message-label {
  padding-left: 10px;
  padding-right: 0px;
}

.message {
  padding-left: 0px;
  padding-right: 0px;
}

p {
  line-height: 1.5; /* Increase the line spacing between paragraphs */
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.AppLogin {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-image: url('search-bg.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.login-modal {
  background-color: rgba(255, 248, 248, 0.95);
  padding: 50px;
  border-radius: 20px;
  box-shadow: 0 4px 20px rgba(0,0,0,0.1);
  text-align: center;
  max-width: 600px;
  width: 100%;
}

.login-modal h2 {
  color: #333;
  margin-bottom: 20px;
}

.login-modal p {
  color: #555;
  margin-bottom: 30px;
}

.login-container {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.login-box {
  background-color: rgba(255, 255, 255, 0.95);
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 2px 10px rgba(0,0,0,0.1);
  text-align: center;
  width: 45%;
}

.login-box h2 {
  color: #333;
  margin-bottom: 20px;
}

.login-button {
  background-color: #150065; /* Google blue */
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.login-button:hover {
  background-color: #00ffc8aa; /* Slightly darker blue on hover */
}

.email-login {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.email-input {
  padding: 10px;
  margin-bottom: 10px;
  width: 80%;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.login-message {
  color: #333;
  margin-top: 10px;
}


.admin-sidebar {
    display: flex;
    flex-direction: column;
    width: 220px;
    padding-left:5px;
    padding-right: 5px;
    color: #ffffff;
    background-color: #150065;
    position: relative; /* Needed to position children absolutely within */
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    font-size: 14px;
    overflow: hidden; /* Ensure overflow is hidden */
    min-height: 70%;
    min-width: 110px;
  }

.admin-sidebar ul {
  list-style-type: none;
  padding: 0;
}

.admin-sidebar li {
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
}

.admin-sidebar li.active {
  background-color: #9d9b9b77;
}

.admin-sidebar li:hover{
  background-color: #00ffc8aa;
}

.message h1 h2 li text a h3 {
  color: #150065;
}
.toggle-side-menu-button {
  z-index: 11000;
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: #150065;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}

.toggle-side-menu-button-admin {
  z-index: 11000;
  position: absolute;
  top: 5px;
  background-color: #150065;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}


.toggle-side-menu-button:hover {
  background-color: #00ffc8aa;
}

.full-width {
  z-index: 10000;
  width: 100%;
}

.admin-content {
  padding-left: 30px;
  transition: width 0.3s;
  z-index: 9999;
  overflow-y: auto; 
  margin-bottom:60px;
  width: 100%;
}

.admin-content h2{
  color:rgb(38, 33, 33)
}

.admin-content > div {
  padding-left: 20px;
  max-height: 100%; 
  max-width: 100%;
}

.admin-content  p { 
  max-width: 95%;
}

.modal {
  padding-top:20px;
  z-index: 1;
  position: fixed;
  overflow-y: auto;
  top: 0;
  left:0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.modal h2, h3 {
  color:white
}
.modal ul li {
  color:white
}

.modal-content {
  background-color: #150065;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 8px;
  width: 100%;
  height: 100%;
  max-width: 700px;
  overflow-y: auto;
  padding-bottom: 20px;
  margin-left: 80px;
  margin-bottom: 40px;
}

.form-group-inline {
  margin-bottom: 5px;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem; /* Adjust the spacing between the elements as needed */
}

.form-group-inline .form-group {
  flex: 1;
  min-width: 200px; /* Adjust the minimum width as needed */
}

.create-assistant-form {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 5px;
}

.form-group label {
  display: block;
  color: #ccc;
  margin-bottom: 5px;
}

.form-control {
  margin-bottom: 15px;
  width: 100%;
  padding: 10px;
  border: 2px solid #555;
  background-color: white;
  color: grey;
  border-radius: 4px;
  box-sizing: border-box;
}

.form-control:focus {
  outline: none;
  border-color: #777;
}

.form-control-specific{
  width: 49%;
}

.btn {
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 10px;
  display: inline-flex;
  align-items: center;
}

.btn-primary {
  background-color:#9d9b9b77;
  color: #fff;

}

.btn:hover{
  background-color: #00ffc8aa;
}

.btn-secondary {
  background-color: #9d9b9b77;
  color: #fff;
}

.danger-btn {
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 10px;
  background-color: rgb(183, 42, 42);
  color: white;
  display:fixed
}

.remove-btn {
  border: none;
  border-radius: 10px;
  cursor: pointer;
  margin-left: 10px;
  background-color:rgb(183, 42, 42);
  color: white;
  display:fixed,
}


.danger-btn:hover, .remove-btn:hover{
  background-color: rgb(225, 87, 87);
}

.form-control {
  margin-top:10px;
}

.edit-btn, .save-btn, .cancel-btn {
  margin-top: 20px;
  margin-left: 10px;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 10px;
  background-color: #150065;
  color: white;
  display: inline-flex;
}

.edit-btn:hover, .save-btn:hover, .cancel-btn:hover {
  background-color: #2c5396;
}

.custom-file-input{
  margin-bottom: 30px;;
}

.form-actions {
  display: flex;
  justify-content: flex-end;
}

.assistants-menu select{
  margin-left: 40px;
  border-radius: 10px;
  border: none;
  background-color: #d1cfcf77;
  width: 300px;
  color: black
}

.assistants  select, .vector-stores select, .security-groups select{
    border-radius: 10px;
    border: none;
    background-color: #d1cfcf77;
    width: 300px;
    color: black
  }

  .dropdown-menu {
    position: absolute;
    background-color:  #0f0340;
    color: white; /* Text color */
    margin-left: 5px;
    margin-top: 4px;
    padding-top:10px;
    padding-bottom:10px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    z-index: 1000; /* Ensure it sits above other content */
    width: 180px; /* Match the width of the sidebar */
    top: 45px; /* Adjust this based on the exact position of the button */
    left: 0; /* Align with the side-menu-button */
    display: none; /* Initially hidden, will be shown on hover */
  }
  
  .side-menu-button:hover .dropdown-menu {
    display: block; /* Show dropdown on hover */
  
  }
  
  .dropdown-menu div {
    padding-left: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    cursor: pointer; /* Cursor indication for clickable items */
    transition: background-color 0.3s; /* Smooth transition for hover effect */
    border-radius: 10px;

  }
  
  .dropdown-menu div:hover {
    background-color: #00ffc8aa; /* Lighter shade on hover for interaction feedback */
    border-radius: 10px;
  }


/* Styles for the table */
.user-activity-table {
  width: 60%;
  border-collapse: collapse;
  margin-top: 20px;
}

.user-activity-table th, .user-activity-table td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.user-activity-table th {
  background-color: #f2f2f2;
}

.user-activity-table tr {
  height: 40px; /* Increase the space between lines */
}

.user-activity-table td.right-align {
  text-align: right; /* Align message count to the right */
}

.user-activity-table th.right-align {
  text-align: right; /* Align message count to the right */
}

.admin-title {
  position:sticky;
  top: 0; /* Position it at the top */
  background-color: #ffffff; /* Example background color */
}

.hint-buttons {
  display: flex;
  flex-wrap: wrap; /* Allow wrapping to the next line if needed */
  justify-content: center;
  gap: 10px;
  padding: 10px;
}

.hint-buttons button {
  flex: 1 1 auto; /* Allow buttons to grow and shrink */
  width: 140px; /* Minimum width for buttons */
  height: auto; /* Allow height to adjust based on content */
  border-radius: 5px; /* Slightly rounded corners */
  border: 1px solid #ccc; /* Border styling */
  background-color: #f9f9f9; /* Light background color */
  padding: 10px; /* Padding for some space inside the button */
  font-size: 14px; /* Font size */
  color:grey;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s ease; /* Smooth background color transition */
  text-overflow: clip; /* No ellipsis */
  white-space: pre-wrap; /* Preserve white space */
  word-wrap: break-word; /* Ensure long words break properly */
  overflow: hidden; /* Hide any overflow */
  line-height: 1.5; /* Line height for proper spacing */
  min-height: 3em; /* Ensure at least 2 lines */
}

.hint-buttons button:hover {
  background-color: #00ffc8aa; /* Slightly darker background on hover */
}

.submit-button {
  background-color: #150065;
  color: white;
  height: 50px;
  width: 50px;
  padding: 10px 20px;
  border-radius: 50%;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-button:hover {
  background-color: #00ffc8aa;
}

.submit-button:disabled {
  background-color: #ddd; /* Change the color when the button is disabled */
  cursor: not-allowed;
}

.chat-input-textarea.disabled {
  background-color: #f3f3f3; /* Light grey background */
  color: #ccc; /* Greyed out text color */
  cursor: not-allowed; /* Shows a 'not allowed' cursor when hovering */
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #00ffc8aa; /* Adjust the color as needed */
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 1s linear infinite;
  margin-left: 10px; /* Adjust as needed */
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


/* Ensure the assistant cards container is centered on the page */
.assistant-cards-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.assistant-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  padding: 20px;
  max-width: 800px; /* Set a max width if needed */
}

.assistant-cards-title {
  margin-bottom: 20px;
  font-size: 24px;
  text-align: center;
}

.assistant-card {
  flex: 1 1 30%; /* Allow cards to grow and shrink, and set a base width of 30% */
  max-width: 30%; /* Ensure cards don't exceed 30% of the container width */
  height: auto; /* Allow height to adjust based on content */
  border-radius: 5px; /* Slightly rounded corners */
  border: 1px solid #ccc; /* Border styling */
  background-color: #14006582; /* Light background color */
  padding: 10px; /* Padding for some space inside the card */
  font-size: 14px; /* Font size */
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s ease; /* Smooth background color transition */
  text-overflow: clip; /* No ellipsis */
  white-space: pre-wrap; /* Preserve white space */
  word-wrap: break-word; /* Ensure long words break properly */
  overflow: hidden; /* Hide any overflow */
  line-height: 1.5; /* Line height for proper spacing */
  min-height: 3em; /* Ensure at least 2 lines */
}

.assistant-card:hover {
  background-color: #150065; /* Slightly darker background on hover */
}

.domain-list-box, .user-list-box {
  padding: 10px;
  border-radius: 4px;
  background-color: #f9f9f9;
  margin-bottom: 15px;
  max-width: 300px;
  overflow-y: auto;
}

.domain-chip, .user-chip {
  display: inline-block;
  padding: 5px 10px;
  margin: 5px;
  border-radius: 15px;
  background-color: #e0e0e0;
  font-size: 14px;
}